@import "../../public/assets/glyphicons/glyphicons.css";
@import "../../public/assets/glyphicons/glyphicons-filetypes.css";
$fa-font-path: "../../node_modules/@fortawesome/fontawesome-free/webfonts";
$fa-font-display: swap;
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/v4-shims.scss";
/*@import '../_lib/animate.css/animate.css';*?
we're loading this in the header now
 */
